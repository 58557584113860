
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import type { RouteLocationRaw } from 'vue-router'
import { senderSettingsOptions, recipientOptions } from '@/definitions/_general/_data/optionsList'

export default defineComponent({
  components: {
    TmModal,
    TmButton,
    TmFormLine,
  },
  props: {
    customText: {
      type: String,
    },
    link: {
      type: Object as PropType<RouteLocationRaw>,
    },
  },
  setup() {
    const value = ref(senderSettingsOptions[0])
    const recipient = ref([...recipientOptions.slice(0, 3)])

    return {
      value,
      recipient,
      recipientOptions,
      senderSettingsOptions,
    }
  },
})
